@use 'node_modules/angular-component-library/sass/cvs-brand/cvs-variables' as variables;
@import "node_modules/angular-component-library/sass/styles";


@import 'node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import 'node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';


///AG GRID STYLING///
.ag-theme-alpine {
  @include ag-theme-alpine();
  --ag-odd-row-background-color:#F9F9F9;
  --ag-border-color:#AAA;
  --ag-selected-row-background-color: #ECECEC;
  --ag-row-hover-color: #ECECEC;

  .ag-row-selected::before {
    content: none !important;
  }

  font-size:11px;
  font-family:(Helvetica,Arial,sans-serif);
}

.ag-header-cell-text {
  font-size: 14px;
  white-space: pre-line !important;
  text-align: left;
  width: 100%;
}

.ag-theme-alpine .ag-row-hover.ag-row-selected::before {
  background-image: none;
}

.ag-cell-value {
  text-align: left;
}

.ag-header-group-cell {
  background-color: #E9E9E9;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
  overflow: visible;
}

.ag-header-cell {
  background-color: #E9E9E9;
}

.ag-font-style {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}

.ag-header-viewport {
  background-color: #E9E9E9;
}

.ag-floating-top {
  background-color: #E9E9E9;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-floating-filter-full-body {
  justify-content: center;
}

.ag-theme-alpine .ag-tabs {
  min-width: 265px;
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
}

/////app-user-table grid//////////////
app-user-table {
  .ag-cell:hover {
    cursor: pointer;
  }
}

////////client-profile clients grid////////////
.client-profile-clients-grid {
  margin-top: 10px;
  height: 170px;
  width: 100%;

  .ag-row {
    border: none !important;
  }

  .ag-header {
    display:none !important;
  }

  .ag-row-odd, .ag-row-hover:not(.ag-full-width-row)::before {
    background-color: white;
  }
}

//////////////////////////////END OF GRID STYLING///////////////

app-offshore-renderer {
  line-height: normal;
  width: 100%;
  text-align: center;
}

app-table-action-cell-renderer {
  height: 100%;
  display: flex;
  align-items: center;
}

.table-action-clicked {
  background-color: #DBF1F5 !important;
}

.row-table-action-clicked, .role-unable-to-delete {
  background-color: #1EA1924D !important;
}

.unable-to-delete {
  background-color: #F1F9FB !important;
}

cvs-base {
  visibility: hidden;
  main {
    background-color: variables.$gray-1;
  }
}

.legal-modal {

  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }

  .list-info {
    min-height: 70vh;
  }
}

.client-profile-modal {
  .mat-dialog-container {
    width: 1500px;
  }
}

.client-group-modal {
  .mat-dialog-container {
    width: 1500px;
  }
}

.hyperlink-modal{
  .list-info{
    min-height: 70vh;
  }
}

.adjustHeight{
  min-height: 500px;
}

.header-padding {
  padding-bottom: 24px;
}

main {
  overflow-x: hidden !important;
}

.back-button {
  justify-content: start;
}

.back-button-icon {
  padding-right: 0;
}

.box {
  display:flex;
  align-items: stretch;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
  min-height: 50vh;
}

.tile__background-border {
  min-height: 80%;
  height: fit-content;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.3), 3px 2px 6px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.0465472);
  border-radius: 6px;
  margin: auto;
}

.content-card {
  .content-card-add-edit-header {
    margin: 0 0 24px 0;
  }
  min-height: 80%;
  height: fit-content;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.3), 3px 2px 6px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.0465472);
  border-radius: 6px;
  margin: auto;
}

.back-button-container {
  padding-bottom: 18px;
}

.delete-confirmation-modal {
  mat-dialog-container {
    .red-delete-warning {
      color:#CC0000 !important;
      margin: 0 0 0;
    }
    .delete-spacing {
      margin: 0 0 8px;
    }
  }
}

.role-info-modal {
  mat-dialog-container {
    #headlineValue {
      color: #0B315E;
    }

    table {
      margin-top: 12px;
      border: 1px solid #CCCCCC;
      border-spacing: 0;
      width: 692px;

      .role-id-header {
        width: 157px
      }

      .role-desc-header {
        width: 534px
      }
    }

    th {
      padding: 10px 8px;
      text-align: left;
      background: #F2F2F2;
    }

    td {
      font-size: 12px;
      padding: 12px 8px 9px 8px;
    }

    .mat-raised-button {
      @extend .cvs-btn-white;
    }

    .mat-raised-button:hover {
      border-bottom-color: transparent !important;
    }


  }
}

.permission-icon {
  background: #00A78E;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 8px;
}

.permission-icon::after {
  content: "P"
}

.text-nowrap {
  white-space: nowrap;
  max-width: unset !important;
}

.overview-tooltip {
  text-align: left !important;
  line-height: 21px !important;
  max-width: 278px !important;
}

.error-icon {
  font-size: medium !important;
  padding-right: 8px;
}

.add-edit-error {
  display: flex !important;
  align-items: center;
}

.access-name-header {
  margin-left: 35px;
}

.nav-menu-list a, a:visited, a:active {
  font-size: 0.99rem !important;
}

.modal-hyperlink {
  float: left;
  padding-right: 4px;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dark-overlay {
  width: 107% !important;
  height: 107% !important;
}
